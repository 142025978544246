$font-family-sans-serif: Satoshi, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #272F31;

$primary: #C2F94B;
$dark: #272F31;
$secondary: #95BF39;

$navbar-dark-color:                rgba($primary, 0.7);
$navbar-dark-hover-color:          rgba($primary, 1);
$navbar-dark-active-color:         rgba($primary, 1);
$navbar-dark-disabled-color:       rgba($primary, 0.3);

$border-radius: 30px;