@font-face {
    font-family: "Satoshi";
    src: local("Satoshi-Light"), url("../../public/fonts/Satoshi-Light.otf") format("opentype");
    font-weight: 300;
}
@font-face {
    font-family: "Satoshi";
    src: local("Satoshi-Regular"), url("../../public/fonts/Satoshi-Regular.otf") format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: "Satoshi";
    src: local("Satoshi-Medium"), url("../../public/fonts/Satoshi-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Satoshi";
    src: local("Satoshi-Bold"), url("../../public/fonts/Satoshi-Bold.otf") format("opentype");
    font-weight: 700;
}