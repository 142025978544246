.navbar{
    min-height: 120px;
}
.nav-link{
    text-align: center;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}
.nav-link .btn{
    text-align: center;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

#home{
    margin-top: 120px;
    min-height: calc(100vh - 120px);
    background: url("../../public/section-1-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#home h1{
    font-size: clamp(3.5rem, 5vw + 1rem, 6rem);
    font-weight: 300;
}
#home b{
    font-weight: 700;
}

#von{
    min-height: 100vh;
    background: url("../../public/section-2-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    font-size: 18px;
    font-weight: 400;
    line-height: 38px;
}
#von b{
    font-weight: 700;
}

#funcionalidades{
    background-color: $primary;
    margin-right: calc(calc(var(--bs-gutter-x) * 0.5) * -1);
    margin-left: calc(calc(var(--bs-gutter-x) * 0.5) * -1);
}
#funcionalidades .funcionalidades h2{
    font-size: 3rem;
}
#funcionalidades .funcionalidades p{
    font-size: 18px;
    line-height: 32px;
}
#funcionalidades .funcionalidades-cards{
    position: absolute;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    white-space: nowrap;
    right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -20px;
    scroll-behavior: smooth;
}
#funcionalidades .funcionalidades-cards::-webkit-scrollbar-track {
    background-color: transparent;
}
#funcionalidades .funcionalidades-cards::-webkit-scrollbar {
    height: 0;
    background-color: transparent;
}
#funcionalidades .funcionalidades-cards::-webkit-scrollbar-thumb {
    background-color: transparent;
}

#credores h2{
    font-size: 3rem;
}
#credores ul {
    list-style: none;
}
#credores ul li{
    line-height: 38px;
}
#credores ul li::before {
    content: "\2022";
    color: $primary;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

#administracao{
    background: url("../../public/administradora-background.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
}
#administracao h2{
    font-size: 3rem;
}
#administracao .card{
    transition: all 100ms ease-out;
}
#administracao .card:hover{
    background: $primary !important;
    color: $dark !important;
}
#administracao .card:hover .dot{
    background-color: $dark;
}
#administracao .dot{
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background-color: $primary;
    overflow: hidden;
}

#recuperandas{
    background-color: #E9F9C7;
    margin-right: calc(calc(var(--bs-gutter-x) * 0.5) * -1);
    margin-left: calc(calc(var(--bs-gutter-x) * 0.5) * -1);
}
#recuperandas h2{
    font-size: 3rem;
}
#recuperandas ul {
    list-style: none;
}
#recuperandas ul li{
    line-height: 24px;
}
#recuperandas ul li::before {
    content: "";
    border-radius: 10px;
    background-color: #A9D941;
    font-weight: bold;
    display: inline-block; 
    width: 10px;
    height: 10px;
    margin-left: -20px;
    margin-right: 10px;
}

#footer .rede-social{
    color: #999F9A;
    border-radius: 100px;
    border: 2px solid #999F9A;
}
#footer .rede-social:hover{
    color: $dark;
    background-color: $primary;
    border: 2px solid $primary;
}

.fadeIn{
    animation: fadeIn 800ms ease-out;
}
.fadeInHidden{
    opacity: 0;
}

@keyframes fadeIn {
    from{
        opacity: 0;
        transform: translateX(-500px);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}